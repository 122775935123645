@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');

/*#region Body --------------------------------------*/

body,
a,
a:hover {
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    color: #666666;
    text-decoration: none;
}

.whitetxt,
.whitetxt p,
.whitetxt strong,
.whitetxt H1,
.whitetxt H2,
.whitetxt H3,
.whitetxt H4,
.whitetxt H5,
.whitetxt H6,
.whitetxt a {
    color: #ffffff;
}

.errortxt,
.errortxt p,
.errortxt strong,
.errortxt H1,
.errortxt H2,
.errortxt H3,
.errortxt H4,
.errortxt H5,
.errortxt H6,
.errortxt a {
    color: #bf4e45;
    font-weight: 600;
}

.danger {
    color: #bf4e45 !important;
    fill: #bf4e45 !important;
}

#search,
.txtBox {
    border: 1px solid #cccccc;
    border-radius: 7px;
    padding: 3px;
    margin: 3px;
    width: 100%;
}

.small,
small {
    font-size: 0.75em;
}

.bg-grey {
    background-color: #999999;
}

.text-primary {
    color: #32bebe !important;
}

.bg-primary {
    background-color: #32bebe !important;
}
/*#endregion*/

/*#region Header ------------------------------------*/
.img-fluid {
    width: 250px;
}

.header .navbar,
.subHeader,
.subHeader .navbar {
    line-height: 1;
    padding: 0 !important;
    margin: 0 !important;
    border: 0 !important;
}

.header {
    background-color: #ffffff !important;
    z-index: 90;
}
.subHeader,
.subHeader .navbar {
    background-color: #32bebe;
}

.nav-link {
    padding: 0px 20px !important;
}

.pageFunctions.sticky-top {
    top: 35px !important;
}
/*#endregion*/

/*#region Customer ----------------------------------*/

input::placeholder {
    color: #cccccc !important;
    font-style: italic;
    font-weight: 200;
}

.ROTooltip {
    /* border-radius: 50%; */
    /* background-size: cover; */
    /* background-position: center; */
    position: relative;
}

.ROTooltip::after {
    background-color: #777777 !important;
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    font-size: 1rem !important;
    --scale: 0;
    position: absolute;
    top: -0.25rem;
    left: 50%;
    transition-delay: 0ms;
    transform: translateX(-50%) translateY(-100%) scale(var(--scale));
    transition: 300ms transform;
    transform-origin: bottom center;
    content: attr(data-tooltip);
    color: white;
    padding: 0.5rem;
    border-radius: 0.3rem;
    text-align: center;
    width: max-content;
    background: #333;
    z-index: 99;
}

.ROTooltip:hover::after {
    --scale: 1;
    transition-delay: 750ms;
    z-index: 99;
    /*transition: 300ms transform;*/
}

.icoSVG {
    fill: #32bebe;
    display: inline;
}

.subHeader .icoSVG {
    fill: #ffffff;
}

.interfAddnewcust {
    color: #32bebe;
}

.custName,
.custCont,
.custEmail,
.ROnormaltxt,
.ROmaintxt {
    font-size: 1.2rem !important;
    font-weight: 300;
}
.custName,
.ROmaintxt {
    font-weight: 600;
}

/* .custEmail :before {
    content: '\f2b6';
    font-family: 'FontAwesome';
    font-weight: 400;
}*/

.custLocation .far {
    color: #32bebe;
}

.custLocation {
    padding-right: 20px;
    font-size: 14px;
}
.custLocadd {
    padding-left: 3px;
}

.border-primary {
    border-color: #32bebe !important;
}

.bg-white {
    background-color: #fafafa !important;
}
.bg-servicewhite {
    background-color: #fff !important;
}
.bg-secondary {
    background-color: #efefef !important;
}

.primaryCont {
    font-weight: 400;
}
/*#endregion*/

/*#region Customer Chats ----------------------------*/

input#txtContactName {
    width: 100%;
}

.custChatMessage {
    padding-right: 20px;
    font-size: 14px;
    font-weight: 400;
    font-style: italic;
}

.custChatDateTime {
    padding-right: 20px;
    font-size: 10px;
    font-weight: 300;
}

.borderChat {
    border: 1px solid #b0b0b0 !important;
}

/*#endregion*/

/*#region Dropdown -------------------------------------*/
/* The container <div> - needed to position the dropdown content */
.dropdown {
    position: relative;
    display: inline-block;
}
.ROdropdown {
    position: relative;
    display: inline-block;
    width: 95%;
}
.ROdropdownFull {
    position: relative;
    display: inline-block;
    height: 100%;
    width: 100%;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
    /*display: none;*/
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    width: 95%;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    left: 2%;
}

/* Links inside the dropdown */
.dropdown-content div {
    color: black;
    padding: 6px 10px;
    text-decoration: none;
    display: block;
}
/* Change color of dropdown links on hover */
.dropdown-content div:hover {
    background-color: #ddd;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
    display: block;
}

/*#endregion*/

/*#region Pages -------------------------------------*/

.pageHead {
    padding: 10px;
}
/*#endregion*/

/*#region Other -------------------------------------*/
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    height: 100vh;
}

ul {
    list-style: none;
}

li {
    line-height: 2.2;
}

.loginContainer {
    max-width: 600px;
    min-width: 400px;
}

#roIcon {
    color: '#32BEBE' !important;
}

/*my own crap start*/
/* New Services Code Jayson */
hr.services {
    border: 1px solid #32bebe;
}

.serviceBox {
    color: #666666;
    box-sizing: border-box;
}

.regular-checkbox {
    -webkit-appearance: none;
    background-color: #fafafa;
    border: 1px solid #cacece;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
        inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 9px;
    border-radius: 3px;
    display: inline-block;
    position: relative;
    margin-top: 5px;
    margin-left: 3px;
}

.regular-checkbox:active,
.regular-checkbox:checked:active {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
        inset 0px 1px 3px rgba(0, 0, 0, 0.1);
}

.regular-checkbox:checked {
    background-color: #e9ecee;
    border: 1px solid #adb8c0;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
        inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05),
        inset 15px 10px -12px rgba(255, 255, 255, 0.1);
    color: #99a1a7;
}

.regular-checkbox:checked:after {
    content: '\2714';
    font-size: 14px;
    position: absolute;
    top: 0px;
    left: 3px;
    color: #99a1a7;
}

.w-container {
    display: block;
    min-height: 400px;
    -webkit-box-pack: start;
    justify-content: flex-start;
    -ms-flex-pack: start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    align-items: flex-start;
}

.columns {
    order: 0;
    flex: 1;
}

.lcol {
    background-color: #ff0000;
    /*text-align: left !important; !!! Wanted to check what the effect is without this */
}

.icofloatright {
    float: right;
    padding-right: 10px;
    color: #32bebe;
    /*display: inline-block;
    position: right;*/
}

.section {
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-wrap: nowrap;
    align-items: flex-start;
    flex: 0 auto;
    background-color: #9b9b9b;
}

.div-block {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: flex-start;
    background-color: #9b9b9b;
}

.custLocation {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: flex-start;
    /*background-color: #555;*/
}

/* Customer Details Section Start*/
.custDetails {
    width: 95%;
    display: block;
    align-items: left;
    text-align: left;
    /*background-color: #555;*/
}

.floatContactsRight {
    float: right;
    margin-right: 60px;
    min-width: 600px;
}

.mainCustomerAddress {
    display: inline-block;
    position: left;
    text-align: left;
    position: left;
    margin-left: 10px;
    /*background-color: #555;*/
}
/* Customer Details Section End*/

/*.expandedLocationsDiv {
    display: block;
    position: left;
    text-align: left;
    margin-left: 10px;
}*/

/*my own crap end*/

.welcomeBox {
    max-width: 1080px;
    margin: 0 auto;
}

.content {
    /*width: 70%;*/
    margin: 0 auto;
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}
/*#endregion*/
